import { SET_END_VALUE, SET_PERCENTAGE_VALUE, SET_START_VALUE, SWAP_VALUES } from "../CONSTANTS";

export function setStartValue(payload) {
  return { type: SET_START_VALUE, payload };
}

export function setEndValue(payload) {
  return { type: SET_END_VALUE, payload };
}

export function setPercentageValue(payload) {
  return { type: SET_PERCENTAGE_VALUE, payload };
}

export function swapValues(payload) {
  return { type: SWAP_VALUES, payload };
}
