import { Decimal } from "decimal.js";

export const calculatePercentage = (startValue, endValue) => {
  return String(((endValue - startValue) / startValue) * 100);
};

export const onPercentage = (percentage, startValue) => {
  if (!percentage) {
    return 0;
  }

  const startValueDecimal = new Decimal(startValue);
  return startValueDecimal
    .dividedBy(100)
    .mul(percentage)
    .plus(startValue);
};
