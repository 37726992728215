import { SET_END_VALUE, SET_PERCENTAGE_VALUE, SET_START_VALUE, SWAP_VALUES } from "../CONSTANTS";
import { calculatePercentage, onPercentage } from "../utils";

const initialState = {
  startValue: "",
  endValue: "",
  percentageValue: ""
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_START_VALUE:
      return {
        ...state,
        startValue: action.payload,
        percentageValue: calculatePercentage(action.payload, state.endValue)
      };
    case SET_END_VALUE:
      return {
        ...state,
        endValue: action.payload,
        percentageValue: calculatePercentage(state.startValue, action.payload)
      };
    case SET_PERCENTAGE_VALUE:
      return {
        ...state,
        endValue: onPercentage(action.payload, state.startValue),
        percentageValue: action.payload
      };
    case SWAP_VALUES:
      return {
        ...state,
        startValue: state.endValue,
        endValue: state.startValue,
        percentageValue: calculatePercentage(state.endValue, state.startValue)
      };
    default:
      return state;
  }
};

export default rootReducer;
