import React, { Component } from "react";
import "./../custom.css";
import { setStartValue, setEndValue, setPercentageValue, swapValues } from "../actions";
import { connect } from "react-redux";

function mapDispatchToProps(dispatch) {
  return {
    setStartValue: startValue => dispatch(setStartValue(startValue)),
    setEndValue: endValue => dispatch(setEndValue(endValue)),
    setPercentageValue: percentageValue =>
      dispatch(setPercentageValue(percentageValue)),
    swapValues: () => dispatch(swapValues()),
  };
}

class Form extends Component {
  handleChange = e => {
    switch (e.target.name) {
      case "startPrice":
        this.props.setStartValue(e.target.value);
        break;
      case "endPrice":
        this.props.setEndValue(e.target.value);
        break;
      case "percentage":
        this.props.setPercentageValue(e.target.value);
        break;
      default:
        return;
    }
  };

  render() {
    return (
      <div className="ui middle aligned center aligned grid" id="perc">
        <div className="column">
          <h2 className="ui teal image header">
            <div className="content">Calculate percentage Gain/Loss</div>
          </h2>

          <form className="ui large form">
            <div className="ui stacked segment">
              <div className="field">
                <div className="ui massive left icon input">
                  <i className="chart bar icon" />
                  <input
                    type="number"
                    name="startPrice"
                    id="startPrice"
                    placeholder="Start Price"
                    value={this.props.startValue}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="field">
                <div
                  className="ui fluid large teal submit button"
                  onClick={this.props.swapValues}
                >
                  <i className="exchange icon big" />
                  Swap
                </div>
              </div>

              <div className="field">
                <div className="ui massive left icon input">
                  <i className="chart bar icon" />
                  <input
                    type="number"
                    name="endPrice"
                    id="endPrice"
                    placeholder="End Price"
                    value={this.props.endValue}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="field">
                <i className="calculator big icon" />
              </div>
              <div className="field">
                <div className="ui massive left icon input">
                  <i className="percent icon" />
                  <input
                    type="number"
                    name="percentage"
                    id="percentage"
                    placeholder="Percentage"
                    value={this.props.percentageValue}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    startValue: state.startValue,
    endValue: state.endValue,
    percentageValue: state.percentageValue
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
